import React, {useEffect,createContext, useReducer, useContext} from 'react';

import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';


import {Login_std} from './components/Login_std';
import {Login_men} from './components/Login_men';
import  Registration_men from './components/Registration_men'
import  Registration_std from './components/Registration_std'
import Dash_std from './components/Dash_std';
import Dash_men from './components/Dash_men';
import Scholarships from './components/Scholarships';
import StartingPage from './components/StartingPage'
import Search from './components/Search';
import './App.css';
import {reducer,initialState} from './reducers/userReducer'
import Landing from "./components/Landing"
import ToDo_men from './components/ToDo_men';
import ToDo_std from './components/ToDo_std';
import BookMarked from './components/BookMarked';
import CreateCourseForm from './components/CreateCourseForm';
import SinglePage from './components/SinglePage';
import English from './components/English'
import SearchTeacher from './components/SearchTeacher'
import Regulated from './components/Regulated'
import LinkPage from './components/LinkPage'
import Subject from './components/Subjects';
import ClearDoubts from './components/ClearDoubts';
import SingleDoubt from './components/SingleDoubt';
import TeacherSubject from './components/TeacherSubjects';
import ClearDoubtsTeacher from './components/ClearDoubtsTeacher';
import SingleDoubtTeacher from './components/SingleDoubtTeacher';
import Chat from './components/Chat';
import TeacherChat from './components/TeacherChat';
import io from "socket.io-client";
import Videocall from './components/Videocall';
import ProtectedRoutes from './utils/ProtectedRoutes';
import OTP from './components/OTP';
import CreateCourseDzongkha from './components/CreateCourseDzongkha';
import ScheduledCall from './components/ScheduledCall';
import DzongkhaSession from './components/DzongkhaSession';
import SingleDzongkha from './components/SingleDzongha';
import MyDzongkhaSessions from './components/MyDzongkhaSessions';
import PrejoiningSTD from './components/PrejoiningSTD';
import DzongkhaSessionsTutor from './components/DzongkhaSessionsTutor';
import ScheduledCallSTD from './components/ScheduledCallSTD';
import SingleDzongkhaDash from './components/SingleDzongkhaDash';
import SubjectTeacher from './components/subjectteacher';
import Reset from './components/Reset';
import NewPassword from './components/NewPassword';
import ResetStd from './components/ResetStd';
import NewPasswordStd from './components/NewPasswordStd';

export const socket = io("https://www.rigjr.com/");
// export const socket = io("http://localhost:3000/");

export const UserContex = createContext()


// const Routing = ()=>{
//   const {state, dispatch} = useContext(UserContex)
//   const history = useNavigate()

//   // useEffect(()=>{

//   //   const user = JSON.parse( localStorage.getItem("user"))

//   //   if(user){
//   //     dispatch({type:"USER",payload:user})
     
//   //   }else{

//   //     if(history.location.pathname.startsWith('/scholarships')) {
//   //         history.push('/scholarships')
        
//   //     }

//   //     else if(history.location.pathname.startsWith('/loginStudents')) {
//   //         history.push('/loginStudents')
        
//   //     }
//   //     else if(history.location.pathname.startsWith('/loginMentor')) {
//   //         history.push('/loginMentor')
        
//   //     }
//   //     else if(history.location.pathname.startsWith('/registerStudent')) {
//   //         history.push('/registerStudent')
        
//   //     }
//   //     else if(history.location.pathname.startsWith('/registerMentor')) {
//   //         history.push('/registerMentor')
        
//   //     }
//   //     else{
//   //       history.push('/')
//   //     }

          
//   //   }

//   // },[])

//   return(
//     <></>

//     // <Switch>
//     //    <Route exact path ='/StudentDashboard' >
//     //      <Dash_std/>
//     //     </Route>
//     //    <Route exact path ='/landing' >
//     //      <Landing/>
//     //     </Route>
//     //    <Route exact path ='/search' >
//     //      <Search/>
//     //     </Route>
//     //    <Route exact path ='/subject' >
//     //      <Subject/>
//     //     </Route>
//     //    <Route exact path ='/teachersubject' >
//     //      <TeacherSubject/>
//     //     </Route>
//     //    <Route exact path ='/cleardoubts/:subjectid' >
//     //      <ClearDoubts/>
//     //     </Route>
//     //    <Route exact path ='/cleardoubtsteacher/:subjectid' >
//     //      <ClearDoubtsTeacher/>
//     //     </Route>
//     //    <Route exact path ='/searchTeacher' >
//     //      <SearchTeacher/>
//     //     </Route>
//     //    <Route exact path ='/MentorDashboard' >
//     //      <Dash_men/>
//     //     </Route>
//     //    <Route exact path ='/MentorSchedule' >
//     //      <ToDo_men/>
//     //     </Route>
//     //    <Route exact path ='/StudentSchedule' >
//     //      <ToDo_std/>
//     //     </Route>
//     //    <Route exact path ='/Bookmarked' >
//     //      <BookMarked/>
//     //     </Route>
//     //    <Route exact path ='/createCourse' >
//     //      <CreateCourseForm/>
//     //     </Route>
//     //    <Route exact path ='/videocall' >
//     //      <Videocall/>
//     //     </Route>
//     //    <Route exact path ='/english' >
//     //      <English/>
//     //     </Route>
//     //    <Route  path ='/singlepage/:postId' >
//     //      <SinglePage/>
//     //     </Route>
//     //    <Route  path ='/singledoubt/:postId' >
//     //      <SingleDoubt/>
//     //     </Route>
//     //    <Route  path ='/singledoubtteacher/:postId' >
//     //      <SingleDoubtTeacher/>
//     //     </Route>
//     //    <Route  path ='/regulated' >
//     //      <Regulated/>
//     //     </Route>
//     //    <Route  path ='/chat' >
//     //      <Chat/>
//     //     </Route>
//     //    <Route  path ='/teacherchat' >
//     //      <TeacherChat/>
//     //     </Route>
//     //    <Route  path ='/linkpage' >
//     //      <LinkPage/>
//     //     </Route>
    
        
//     // </Switch>
//   )
// }

function App() {

  const [state, dispatch] = useReducer(reducer,initialState)

  return (
   
    // <>ttrt</>
    <UserContex.Provider value={{state,dispatch}}>

        <Router>
          <Routes>

          <Route exact path="/scholarships" element={<Scholarships/>} />
          <Route exact path="/loginStudents"
           element= {<Login_std/>}
          />
          <Route exact path="/loginMentor" element={<Login_men/>}/>
          <Route exact path="/loginMentor" element={<Login_men/>}/>
            
          <Route exact path="/registerStudent" element={<Registration_std/>}/>
            
          <Route exact path="/registertutor" element={<Registration_men/>}/>
          <Route exact path="/reset" element={<Reset/>}/>
          <Route exact path="/resetstd" element={<ResetStd/>}/>
          <Route exact path="/reset/:token" element={<NewPassword/>}/>
          <Route exact path="/resetstd/:token" element={<NewPasswordStd/>}/>
            
          <Route exact path="/" element={<StartingPage/>}/>
          <Route exact path="/vid" element={<OTP/>}/>
            


          <Route  element={<ProtectedRoutes/>}>
            
          <Route exact path ='/StudentDashboard' element={<Dash_std/>}/>
         
       <Route exact path ='/landing' element={<Landing/>}/>
         
       <Route exact path ='/search' element={<Search/>}/>
         
       <Route exact path ='/subject' element={<Subject/>}/>
       <Route exact path ='/subjectteacher' element={<SubjectTeacher/>}/>
         
       <Route exact path ='/teachersubject' element={<TeacherSubject/>} />
         
       <Route exact path ='/cleardoubts/:subjectid' element={<ClearDoubts/>} />
         
       <Route exact path ='/cleardoubtsteacher/:subjectid' element={<ClearDoubtsTeacher/>} />
         
       <Route exact path ='/searchTeacher' element={<SearchTeacher/>}/>
        
       <Route exact path ='/MentorDashboard' element={<Dash_men/>}/>
         
       <Route exact path ='/MentorSchedule' element={<ToDo_men/>}/>
         
       <Route exact path ='/StudentSchedule' element={<ToDo_std/>}/>
         
       <Route exact path ='/Bookmarked' element={<BookMarked/>}/>
         
       <Route exact path ='/createCourse' element={ <CreateCourseForm/>}/>
       <Route exact path ='/createCoursedzongkha' element={ <CreateCourseDzongkha/>}/>
       <Route exact path ='/dzongkhasessions' element={ <DzongkhaSession/>}/>
       <Route exact path ='/singledzongkha/:dzongkhaid' element={ <SingleDzongkha/>}/>
       <Route exact path ='/prejoiningstd/:dzongkhaid' element={ <PrejoiningSTD/>}/>
       <Route exact path ='/mydzongkhasessions' element={ <MyDzongkhaSessions/>}/>
       <Route exact path ='/dzongkhasessionstutor' element={ <DzongkhaSessionsTutor/>}/>
        

       <Route exact path ='/videocall' element={ <Videocall/>}/>
       <Route exact path ='/schedulecall/:dzongkhaid' element={ <ScheduledCall/>}/>
       <Route exact path ='/schedulecallstd/:classid' element={ <ScheduledCallSTD/>}/>
        
       <Route exact path ='/english' element={<English/>}/>
         
       <Route  path ='/singlepage/:postId' element={ <SinglePage/>}/>
        
       <Route  path ='/singledoubt/:postId' element={<SingleDoubt/>} />
         
       <Route  path ='/singledoubtteacher/:postId' element={<SingleDoubtTeacher/>}/>
         
       <Route  path ='/regulated' element={ <Regulated/>}/>
        
       <Route  path ='/chat' element={ <Chat/>}/>
        
       <Route  path ='/teacherchat' element={ <TeacherChat/>}/>
        
       <Route  path ='/linkpage' element={<LinkPage/>}/>
       <Route  path ='/singledzongkhadash/:dzongkhaid' element={<SingleDzongkhaDash/>}/>
         
    
          </Route>


        </Routes>

        </Router>
    </UserContex.Provider>


  );
}

export default App;
